import React, { useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import {
  UILink,
  Image,
  BreadCrumbs,
  Content,
  HTMLContent,
  Context,
} from "../components"
import { Button } from "@mui/material"
import WebIcon from "@mui/icons-material/Web"
import GitHubIcon from "@mui/icons-material/GitHub"
import gsap, { Elastic } from "gsap/all"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
// eslint-disable-next-line
export const ProjectTemplate = ({
  content,
  contentComponent,
  helmet,
  data: { description, livewebsite, title, featuredimage, sourceCode },
}) => {
  const ProjectContent = contentComponent || Content

  const { state: { isMenuOpen } = { isMenuOpen: false } } =
    useContext(Context) || {}

  const animateProject = () => {
    gsap
      .timeline({
        defaults: {
          ease: Elastic.easeOut.config(0.4, 0.5),
        },
      })
      .set(".project-page__wrapper", { stagger: 0, autoAlpha: 0.3, y: 100 })
      .to(".project-page__wrapper", {
        stagger: 0.2,
        autoAlpha: 1,
        y: 0,
      })
      .fromTo(
        ".project-page__wrapper ul > li ",
        { stagger: 0, autoAlpha: 0 },
        { stagger: 0.05, autoAlpha: 1 },
        "<"
      )
  }

  useEffect(() => {
    if (!isMenuOpen) animateProject()
  }, [isMenuOpen])
  return (
    <div className="container project-page">
      {helmet || ""}
      <BreadCrumbs
        crumbs={{
          current: title,
          parent: { text: "Projects", url: "/projects" },
        }}
      />
      <div className="project-page__header">
        <h1 className="project-page__header--title">{title}</h1>
      </div>
      <div className="project-page__wrapper">
        <p className="description">{description}</p>
        <div className="project-page__content">
          <aside className="project-page__left">
            <Image image={featuredimage} alt={title} />
            <div className="project-page__buttons">
              <UILink to={livewebsite}>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<WebIcon />}
                >
                  Live Website
                </Button>
              </UILink>
              {sourceCode && (
                <UILink to={sourceCode}>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<GitHubIcon />}
                  >
                    Source Code
                  </Button>
                </UILink>
              )}
            </div>
          </aside>
          <aside className="project-page__right">
            <ProjectContent content={content} />
          </aside>
        </div>
      </div>
    </div>
  )
}

const Project = ({ data }) => {
  const { markdownRemark: post } = data
  const ogImage = getSrc(post.frontmatter.featuredimage)

  return (
    <ProjectTemplate
      content={post.html}
      contentComponent={HTMLContent}
      data={post.frontmatter}
      helmet={
        <Helmet titleTemplate={"Tamir Assayag | %s"}>
          <title>{`${post.frontmatter.title}`}</title>
          <meta
            property="og:description"
            content={`${post.frontmatter.description}`}
          />
          <meta
            property="og:title"
            content={`Tamir Assayag | ${post.frontmatter.title}`}
          />
          <meta property="og:image" content={ogImage} />
        </Helmet>
      }
      title={post.frontmatter.title}
    />
  )
}

// eslint-disable-next-line
export default Project
export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        livewebsite
        sourceCode
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`
